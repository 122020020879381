import React, { useEffect, useState } from 'react'
import axe1 from "../assets/axe1.jpg"
import axe2 from "../assets/axe2.jpg"
import axe3 from "../assets/axe3.webp"
import videoBg from "../assets/videoBg.mp4"
import "./App.css"

const Service = () => {
  const [smLayout,setSmLayout]=useState(false)
  const handleLayout=()=>{
    if (window.innerWidth <= 1115) {
      setSmLayout(true);

    } else {
      setSmLayout(false);
    }}

  useEffect(() => {
      handleLayout()
  }, []);

  return (
    <div className='h-fit w-screen relative overflow-hidden' >
      {/* the main title */}
        <div className='w-screen h-[70vh] overflow-hidden'>
            <div className={` backdrop-blur-xl overlay2`}></div>
            <video className='video1'  src={videoBg} autoPlay loop muted/>
            <div className='absolute top-0 mt-[30%] sm:mt-[12%] h-[70vh] w-screen flex  items-start justify-center sm:p-10 z-40'>
                <span className='flex flex-col items-center text-center justify-between w-screen  shadow-white text-white sm:w-[60%]'>
                    <h1 className='uppercase text-3xl sm:text-5xl drop-shadow-lg  font-[700] p-4'>Stratégie de RGA Building. </h1>
                    <p className='sm:pl-2 text-md sm:text-lg sm:font-[500] leading-6 px-1 font-[400] sm:tracking-wide sm:leading-[25px] '>Groupe ETCOD SARL s'engage envers des services de qualité, le respect des délais et budgets, et la satisfaction client. Notre stratégie vise la croissance et l'efficacité des projets. Organisationnellement, nous gérons rentablement, appliquons strictement les procédures, anticipons, et favorisons la créativité des équipes. En ressources humaines, nous améliorons la communication, responsabilisons le personnel, et assurons une formation continue.</p>
                </span>
            </div>
        </div>

        <span className='flex flex-col items-center justify-center  sm:px-0 py-[3%]  bg-slate-100 shadow-md '>
                <h1 className='text-sky-600 underline text-2xl sm:text-3xl p-5 font-[600] uppercase'>Les fondamentaux :</h1>
                <p className='sm:text-2xl flex flex-col sm:items-center text-gray-600 sm:text-left px-4'>
                <p className='mb-2'>Service livrer avec une meilleure qualité, respect des délais et du budget.</p> 
                <p className='mb-2'>Garantir la satisfaction des clients à tous les niveaux.</p>
                <p className='mb-2'> Générer des bénéfices pour assurer la pérennité de notre entreprise.</p> 
                <p className='mb-2'>Responsabilisé de l’ensemble des personnels.</p>
                </p>
            </span>

        <div className='h-fit w-screen py-10 sm:py-20 px-4 sm:px-20 flex flex-col items-center '>
            <h1 className='text-sky-600 capitalize font-[700] text-center text-2xl sm:text-[30px]'>
              Ces fondamentaux essentiels pour orienter nos axes de développements : 
            </h1>

            <aside className='px-[50px] mt-5'>
              <h1 className='text-2xl sm:text-3xl font-bold text-gray-500 underline p-2 sm:p-5 sm:ml-20'>AXES METIERS:</h1>

              <div className='w-screen h-fit flex flex-col sm:flex-row items-center sm:px-[150px] bg-slate-100 pb-10 sm:py-20 shadow-lg '>

              {smLayout &&<img className='sm:w-[40%] w-screen sm:ml-10 rounded-md shadow-xl ' src={axe1} alt={axe1} />}
                <ul className='list-disc mt-4 sm:mt-0 sm:ml-0 ml-6 sm:text-lg font-[600]'>
                  <li className='mt-1'> Etre un partenaire actif et être reconnu comme un acteur majeur dans l’économie</li>
                  <li className='mt-1'>Anticiper, réagir et s’adapter aux tendances lourdes du marché actuel et futur </li>
                  <li className='mt-1'> Augmenter nos activités</li>
                  <li className='mt-1'>Accroitre notre part de marché dans le domaine</li>
                  <li className='mt-1'>Sensibiliser nos clients, nos partenaires et notre personnel à la nouvelle technologie</li>
                </ul>
                {!smLayout &&<img className='sm:w-[40%] ml-10 rounded-md shadow-xl ' src={axe1} alt={axe1} />}

              </div>
            </aside>

            <aside className='px-[50px] mt-5 '>
              <h1 className='text-2xl sm:text-3xl text-gray-500 font-bold underline p-2 sm:p-5 sm:ml-20'>AXES ORGANISATIONNELES:</h1>

              <div className='w-screen h-fit flex flex-col sm:flex-row items-center sm:px-[150px] bg-slate-100 pb-10 sm:py-20 shadow-lg '>
                {smLayout &&<img className='sm:w-[40%] w-screen sm:ml-10 rounded-md shadow-xl ' src={axe2} alt={axe2} />}
                <ul className='list-disc mt-4 sm:mt-0 sm:ml-0 ml-6 sm:text-lg font-[600]'>
                  <li className='mt-1'> Gérer chaque projet comme un centre de profit autonome et rentable</li>
                  <li className='mt-1'> Développer et appliquer avec rigueur les procédures internes du groupe ETCOD SARL  </li>
                  <li className='mt-1'> Anticiper et planifier toutes nos actions </li>
                  <li className='mt-1'>Favoriser la créativité des équipes pour optimiser nos projets </li>
                </ul>
                {!smLayout &&<img className='sm:w-[40%] ml-10 rounded-md shadow-xl' src={axe2} alt={axe2} />}
              </div>
            </aside>

            <aside className='px-[50px] mt-5 '>
              <h1 className='text-2xl sm:text-3xl font-bold underline text-gray-500 p-2 sm:p-5 sm:ml-20'>AXES HUMAINS:</h1>

              <div className='w-screen h-fit flex flex-col sm:flex-row items-center sm:px-[150px] bg-slate-100 pb-10 sm:py-20 shadow-lg '>
               { smLayout &&<img className='sm:w-[40%] w-screen sm:ml-10 rounded-md shadow-xl ' src={axe3} alt={axe3} />}
                <ul className='list-disc mt-4 sm:mt-0 sm:ml-0 ml-6 sm:text-lg font-[600]'>
                  <li className='mt-1'>Améliorer la communication interne</li>
                  <li className='mt-1'> Responsabiliser tout le personnel </li>
                  <li className='mt-1'> Recruter régulièrement pour faire évoluer nos métiers en gardant notre savoir</li>
                  <li className='mt-1'> Former en continu l’ensemble du personnel </li>
                </ul>
                {!smLayout &&<img className='sm:w-[40%] ml-10 rounded-md shadow-xl ' src={axe3} alt={axe3} />}
              </div>
            </aside>
        </div>

    </div>
  )
}

export default Service