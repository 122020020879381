import  { useEffect, useState } from 'react'
import axe1 from "../assets/axe1.jpg"
import axe2 from "../assets/axe2.jpg"
import axe3 from "../assets/axe3.webp"
import videoBg from "../assets/videoBg.mp4"
import "./App.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
export const Specialite = () => {
  return (
    <div className='h-fit w-screen  relative overflow-hidden' >
    {/* the main title */}
        <div className='w-screen h-[70vh] overflow-hidden'>
            <div className={` backdrop-blur-xl overlay2`}></div>
            <video className='video1'  src={videoBg} autoPlay loop muted/>
            <div className='absolute top-0 mt-[30%] sm:mt-[12%] h-[70vh] w-screen flex  items-start justify-center sm:p-10 z-40'>
                <span className='flex flex-col items-center text-center justify-between w-screen  shadow-white text-white sm:w-[60%]'>
                    <h1 className='uppercase text-3xl sm:text-5xl drop-shadow-lg  font-[700] p-4'>Excellence de Service pour un Succès Durable</h1>
                    <p className='sm:pl-2 text-lg leading-7 sm:text-xl sm:font-[400] px-1 font-[400] sm:tracking-wide sm:leading-[25px] '>Nous nous engageons à fournir des services de haute qualité, respectant les délais et les budgets. La satisfaction du client est notre priorité, et la rentabilité constante assure la pérennité de notre entreprise. Nous responsabilisons notre personnel pour favoriser le succès collectif.</p>
                </span>
            </div>
        </div>

        <div className='flex flex-col items-center '>
            
            <span className='flex flex-col items-center justify-center sm:px-[20%] py-[3%] bg-slate-100 shadow-md '>
                <h1 className='text-sky-600 underline text-2xl sm:text-3xl p-5 font-[600]'>LES SERVICES OFFERTS</h1>
                <p className='sm:text-2xl text-gray-600 sm:text-left px-4'>
                    RGA building est en mesure de réaliser un projet de gros et seconds  , particulièrement les travaux suivants:
                </p>
            </span>

            <aside className='px-[50px] mt-5 flex flex-col items-center justify-center'>
              <h1 className='text-xl sm:text-3xl font-bold text-sky-600 underline p-2 sm:p-5 border-2 border-black rounded-xl'>TRAVAUX GENIE CIVIL</h1>
              <ul className='flex flex-col items-start w-screen sm:w-[130%] drop-shadow-2xl shadow-xl mb-10 text-md sm:text-xl bg-slate-100 px-2 sm:px-[15%] py-[7%] mt-[5%] '>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Terrassement et VRD</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Travaux de génie civil pour la construction des plateformes de forage gaz naturel</li>
                <li className='mb-2' ><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Construction des routes</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Terrassement et évacuation</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Ouverture et aménagement des pistes d’accès</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Réalisation des bassins, des bourbiers et les puits perdus</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Bétonnage et dallage</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Assistance et l’aide à l’installation des appareils de forage</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Restauration des plateformes existants</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Etanchéité et Isolation</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Terrasses et allées</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Mure de clôtures</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Electricité générale et sécurité</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Plomberie, Climatisation et chauffage</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Peintures</li>
              </ul>

            </aside>

            <hr className=' p-5 w-[70%]' />

            <aside className='px-[50px] mt-5 flex flex-col items-center justify-center'>
              <h1 className='text-xl sm:text-3xl font-bold text-sky-600 underline p-2 sm:p-5 border-2 border-black rounded-xl'>INSTALLATION ET TRAVAUX DE SOUDURE DES CONDUITES DE GAZ NATUREL</h1>
              <ul className='flex flex-col items-start w-screen sm:w-[130%] drop-shadow-2xl shadow-xl mb-10 sm:text-xl bg-slate-100 px-2 sm:px-[15%] py-[7%] mt-[5%] '>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Travaux de chaudronnerie soudure tuyauterie</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Solide expérience aux travaux de soudure des petites et grands diamètres à haute pression.</li>
                <li className='mb-2' ><FontAwesomeIcon className='px-3' icon={faCheck}/>Elaboration des tranches logement des pipes souterrain 1m50 à 2m de profondeurs</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Travaux de fonçages</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Travaux de centrage pipes</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Réalisation des tests hydraulique</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Travaux de protection de pipes</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Revêtement et peinture des pipes</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Etude de projet global</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Réalisation des plans isométrique</li>
                <li className='mb-2'><FontAwesomeIcon className='px-2 sm:px-3' icon={faCheck}/>Installation et connexion des équipements de surface (Séparateur, réchauffeur, …)</li>
                
              </ul>

            </aside>

            
        </div>

  </div>
  )
}
