import React, { useState } from 'react'
import {sendPasswordResetEmail} from "firebase/auth"
import { auth, database } from '../../config/firebase';
import { useNavigate } from 'react-router-dom';

const Reset = () => {
  const [formData, setFormData] = useState({
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const actionCodeSettings = {
    url: `http://localhost:3000/login`,
    handleCodeInApp: true
  };
  const history=useNavigate()
  const handleSubmit =async(event)=>{
    event.preventDefault()
    try{
      await sendPasswordResetEmail( auth,formData.email,)
        .then((data)=>{
          history("/login")
        })}
        catch(err)  {
          alert(err);
        };
    
  }
  return (
    <div className='w-screen h-screen bg-slate-300 flex flex-col items-center justify-center'>
      <h1 className='text-gray-600 text-5xl font-bold uppercase'>restauration de mot de passe</h1>
        
         <form className='bg-white p-8 flex flex-col items-center justify-center w-fit rounded-lg mt-5 shadow-lg' onSubmit={(e)=>handleSubmit(e)}>
          <div className="mb-4">
              <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="email">
                    Email
                  </label>
              <input
                    className={`shadow appearance-none   border  rounded  py-2 px-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline `}
                    type="email"
                    id="email"
                    onChange={handleChange}
                    name="email"
                    placeholder="Email"
                  />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline duration-100"
            >
              Envoyer
            </button>
          </div>
         </form>
      
    </div>
  )

}

export default Reset