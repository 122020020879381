import React, { useState } from 'react'
import videoBg from "../assets/videoBg.mp4"
import "./App.css"
import logo1 from "../assets/oil/main_img.jpg"
import logo2 from "../assets/sdx/logo.png"
import { Link } from 'react-router-dom'
import Slide from './Slide'
import Crousel from './Crousel'


const Realisation = () => {
    const [isHover,setIsHover]=useState(false);
    const MouseIn=()=>{
      setIsHover(true)
    }
    const MouseOut=()=>{
      setIsHover(false)
    }

    const slides=[
        "./images/projet0.jpeg",
        "./images/projet1.jpeg",
        "./images/projet2.jpeg",
        "./images/projet3.jpeg",
        "./images/projet4.jpeg",
        "./images/projet5.jpeg",
        "./images/projet6.jpeg",

      ]
  return (
    <div className={`h-fit w-screen relative overflow-hidden  `}>
        {/* the main title for this page  */}
        <div className='w-screen h-[70vh] overflow-hidden'>
            <div className={` backdrop-blur-xl overlay2`}></div>
            <video className='video1 h-[1000px]'  src={videoBg} autoPlay loop muted/>
            <div className='absolute top-0 mt-[30%] sm:mt-[12%] h-[70vh] w-screen flex  items-start justify-center p-10 z-40'>
                <span className='flex flex-col items-center text-center justify-between  shadow-white text-white sm:w-[60%]'>
                    <h1 className='uppercase text-5xl drop-shadow-lg  font-[700] pb-4 -mt-10 sm:mt-0'>Nos Realisation</h1>
                    <p className='sm:pl-2 text-sm sm:text-lg font-[500] tracking-wide sm:leading-[25px] '>Notre entreprise excelle dans la réalisation de projets variés en génie civil et construction, allant de la construction de plateformes de forage à la restauration de structures existantes. Ces succès témoignent de notre engagement envers la qualité et l'efficacité, renforçant notre réputation dans le secteur. Chaque réalisation atteste de notre expertise et de notre capacité à relever les défis avec succès.</p>
                </span>
            </div>
        </div>
    <aside className='w-screen flex flex-col items-center justify-center'>
            <div className='w-screen h-fit sm:py-20 mt-10 sm:ml-[300px] sm:px-[100px] flex flex-col sm:flex-row items-center justify-between'>
                <img className='w-screen sm:w-[40%]' src={logo1} alt={logo1} />
                <span className='ml-10 flex flex-col items-start justify-between'>
                    <h1 className='text-2xl sm:text-6xl font-bold w-screen sm:w-[60%] text-yellow-500'>EN 2011-2015 : CIRCLE OIL LIMITED </h1>
                    <Link  to={"/projet1"} className={`btn p-1 sm:p-2 text-xl sm:my-10`}>Afficher les détails du projet</Link>
                </span>
            </div>

            <hr className='my-10 sm:pt-5 w-screen sm:w-[70%] '/>

            <div className='w-screen h-fit pb-10 sm:py-20 sm:ml-[300px] sm:px-[100px] flex flex-col sm:flex-row items-center justify-center'>
                <img className='' src={logo2} alt={logo2} />
                <span className='ml-10 flex flex-col items-start justify-between '>
                    <h1 className='text-2xl sm:text-6xl font-bold w-screen sm:w-[60%] text-yellow-500'>EN 2017-2018 : SOCIETE SDX ENERGY MOROCCO</h1>
                    <Link   to={"/projet2"} className={`btn p-1 sm:p-2 text-xl sm:my-10`}>Afficher les détails du projet</Link>
                    
                </span>
            </div>

            <hr className='my-10 sm:pt-5 w-screen sm:w-[70%] '/>

        <div className='w-screen h-fit pb-10 sm:py-20 sm:ml-[300px] sm:px-[100px] flex flex-col sm:flex-row items-center justify-center'>
        <aside className='sm:w-[30%]   '>
            <Crousel autoSlide={true} autoSlideInterval={3000}>
                    {[...slides.map((image, index) => (
                        <img key={index} src={image} alt={`Project ${index + 1}`} />
                      ))]}
                
            </Crousel>
        </aside>
            <span className='ml-10 flex flex-col items-start justify-between '>
                <h1 className='text-2xl sm:text-6xl pt-4 sm:pt-0 font-bold w-screen sm:w-[60%] text-yellow-500 uppercase' > Dar assalam suissi rabat</h1>
        
    </span>
</div>
    </aside>
        
    
    
    </div>
  )
}

export default Realisation