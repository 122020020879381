import React, { useEffect, useState } from 'react'
import videoBg from "../assets/videoBg.mp4"
import "./App.css"

const Vback = () => {
  const [isHover,setIsHover]=useState(false);
  const [smallerBar,setSmallerbar]=useState(false)
  
  const handleSmallBar=()=>{
    if(window.innerWidth<=1174){
      setSmallerbar(true)
    }else{
      setSmallerbar(false)
    }
 }
  useEffect(()=>{
    window.addEventListener("resize",handleSmallBar)
    handleSmallBar()
    console.log(smallerBar)
  return(
      window.removeEventListener("resize",handleSmallBar)
  )
  },[])
  const MouseIn=()=>{
    setIsHover(true)
  }
  const MouseOut=()=>{
    setIsHover(false)
  }
  const [smLayout,setSmLayout]=useState(false)
  const handleLayout=()=>{
    if (window.innerWidth <= 1115) {
      setSmLayout(true);

    } else {
      setSmLayout(false);
    }}

  useEffect(() => {
      handleLayout()
  }, []);

  return (
    <div className={` main ${smLayout&&"h-[70vh]"} overflow-y-hidden `}>
        <div className={` ${smLayout&&"backdrop-blur-lg "} ${isHover&&"backdrop-blur-lg"} ${smLayout?"overlay2":"overlay"}`}></div>
            <video className='video1' src={videoBg} autoPlay loop muted/>
            <div onMouseEnter={MouseIn} onMouseLeave={MouseOut} className={`content1 sm:content  text-center font-bold text-white`}>
                <h1 className='text-3xl sm:text-5xl w-screen sm:w-[60%] sm:leading-[60px]'>RGA Building: Excellence in Construction et Travaux Génie Civil</h1>
                <p className='text-md sm:text-xl font-normal w-[90%] sm:w-[70%] mt-10'>{smallerBar?"Bienvenue chez RGA Building, où la précision rencontre l'excellence dans la construction, le génie civil et les plates-formes de forage. Notre expertise inclut un travail méticuleux en chaudronnerie, soudure et tuyauterie de gaz naturel. Votre vision est notre priorité ; unissons nos forces pour construire un avenir marqué par la qualité et l'innovation.":"Bienvenue chez RGA Building, où la précision rencontre l'excellence dans la Construction et les Travaux de Génie Civil, y compris la Plateforme de Forage. Notre expertise s'étend aux Travaux minutieux de Chaudronnerie, de Soudure et de Tuyauterie de Gaz Naturel. Votre vision est notre priorité, alors unissons nos forces pour construire un avenir marqué par la qualité et l'innovation."}</p>
            </div>
        
        
    </div>
  )
}

export default Vback